import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {tmsConfig} from '../../../../core/tms-config';
// import {ModalService} from 'sce-ui-lib';
import {AlertService, ModalService, ResourceService, UserService} from 'sce-core';
import {DefaultMapService} from '../../maps/service/default-map.service';
import { Paginator } from 'primeng-lts/paginator';
import { tmsConstant } from 'src/app/features/basic-data/tms-constant';
import { DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { ParamsService } from '../../params.service';
import { catchError, map } from 'rxjs/operators';
import {generateGid} from '../../../../shared/services/business-unit-gid.util';
// import { tmsConstant } from '../../../../core/tms-constant';

@Component({
  selector: 'lfwms-dialog-driver-detail',
  templateUrl: './driver-detail.component.html',
  styleUrls: ['../../shipment/shipment-home/dispatched-order/dispatched-order.component.css'],
  providers: [
    DefaultMapService
  ]
})
export class DriverDetailComponent implements OnInit {

  public searchService: any;
  public cancelHandler: any;
  public confirmHandler: any;
  public confirmHandler2: any;
  public confirmHandler3: Function;
  public columnDef: any[] = [];
  // public listPerPage: any[] = [];
  public servprovGid: any;
  public equipmentGroupGid: any;
  public selectedRow: any;
  showSearch: any = false;
  searchCondition: any = {};
  pageInfo: any = {number: 0, size: 5, total: 0, content: []};
  @ViewChild('paginator')
  paginator: Paginator;

  constructor(public http$: HttpClient, public modalService: ModalService, public alertService: AlertService,
    public userService: UserService,  public paramsService:ParamsService,
    public ref: DynamicDialogRef,
    public resourceService: ResourceService, public mapService: DefaultMapService) {
  }

  public ngOnInit() {

    this.searchCondition.servprovGid = this.paramsService.DriverServprovGid;
    this.queryUserInfo().subscribe(data => {
      this.getBusinessUnitGid(data['userInfo']['userRestrictions'][0]['countryCode'])
    })
    this.mapService.initMapMarker('truck_number_map', '');
    this.columnDef = this.getColumnDef();
  }

  public queryUserInfo(){
    return this.http$.post('/htms/api/user/sce/getUserInfo', JSON.stringify({key: JSON.parse(localStorage.getItem('jwt_token')).token})).pipe(
      map((response: any) => {
        return response;
      }));
  }

  getBusinessUnitGid(value){
    let params: HttpParams = new HttpParams();
    this.http$.get(tmsConfig.tms.getBusinessUnitGid + generateGid(value), {params: params})
       .subscribe((res: any) => {
     this.searchCondition.businessUnitGid = res;
     this.search();
     });
    }

  getColumnDef() {
    return [
      {
        'elementId': this.resourceService.get('lbl_tms_driver_name'), 'propertyName': 'name'
      },
      {
        'elementId': this.resourceService.get('lbl_tms_phone'), 'propertyName': 'phone', 'type': 'DATETIME'
      },
      {
        'elementId': this.resourceService.get('lbl_tms_email'), 'propertyName': 'email', 'type': 'DATETIME'
      },
    ];
  }

  search() {
    let params: HttpParams = new HttpParams();
    Object.keys(this.searchCondition).forEach(k => {
      if (this.searchCondition[k]) {
        params = params.set(k, this.searchCondition[k]);
      }
    });
    params = params.set('page', this.pageInfo.number || 0).set('size', this.pageInfo.size || 10).set('lovCode',tmsConstant.HTMS_DRIVER_ALL_LOV);

    this.http$.get((tmsConfig.tms.lovQueryUri), {params: params})
      .toPromise()
      .then((data) => {
        this.pageInfo = data;
      });
  }

  onConfirm() {
    if(this.paramsService.onlyShow){
      this.ref.close(this.selectedRow)
    }else{
      this.ref.close(DriverDetailComponent)
    }
    // if (this.confirmHandler3) {
    //   this.confirmHandler3(this.selectedRow);
    // }

  }

  onCancel() {
    // if (this.cancelHandler) {
    //   this.cancelHandler();
    // } else {
    //   this.modalService.modalReference.hide();
    // }
    this.ref.close(DriverDetailComponent)

  }

  onPageChange($event: any) {
    this.pageInfo.number = this.paginator.getPage();
    this.pageInfo.size = this.paginator.rows;
    this.search();
  }

  onRowClick($event: any) {
    this.selectedRow = this.selectedRow === $event.data ? null : $event.data;
  }

  updateFilter() {
    setTimeout(() => {
      this.search();
    }, 200);
  }
}
