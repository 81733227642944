import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tmsConfig } from '../../../../core/tms-config';
// import {ModalService} from 'sce-ui-lib';
import {
  AlertService,
  ModalService,
  ResourceService,
  UserService,
} from 'sce-core';
import { DefaultMapService } from '../../maps/service/default-map.service';
import { Paginator } from 'primeng-lts/paginator';
import { DialogService, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { ParamsService } from '../../params.service';
// import {Paginator} from 'primeng/primeng';
import { catchError, map } from 'rxjs/operators';
import { generateGid } from '../../../../shared/services/business-unit-gid.util';

@Component({
  selector: 'lfwms-dashboard-truck-number-detail',
  templateUrl: './truck-number-detail.component.html',
  styleUrls: [
    '../../shipment/shipment-home/dispatched-order/dispatched-order.component.css',
  ],
  providers: [DefaultMapService],
})
export class TruckNumberDetailComponent implements OnInit {
  public searchService: any;
  public cancelHandler: any;
  public confirmHandler: any;
  public confirmHandler2: any;
  public confirmHandler3: Function;
  public columnDef: any[] = [];
  // public listPerPage: any[] = [];
  public servprovGid: any;
  public equipmentGroupGid: any;
  public selectedRow: any;
  showSearch: boolean = false;
  showSearchDriver: boolean = false;
  searchCondition: any = {};
  pageInfo: any = { number: 0, size: 5, total: 0, content: [] };
  @ViewChild('paginator')
  paginator: Paginator;
  countryCode: any;
  Gid: any;

  constructor(
    public http$: HttpClient,
    public modalService: ModalService,
    public alertService: AlertService,
    public userService: UserService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public paramsService: ParamsService,
    public resourceService: ResourceService,
    public mapService: DefaultMapService
  ) {}

  public ngOnInit() {
    // if(!this.servprovGid) {
    //   if (this.searchService) {
    //     this.servprovGid = (this.searchService.getSelectedRows0()[0] || this.searchService.getFocusedRow0()).servprovGid;
    //   }
    // }
    this.showSearch = this.paramsService.showSearch;
    this.searchCondition.servprovGid = this.paramsService.servprovGid;
    this.searchCondition.equipmentGroupGid =
      this.paramsService.TruckEquipmentGroupGid;
    this.queryUserInfo().subscribe(data => {
      this.countryCode = generateGid(
        data['userInfo']['userRestrictions'][0]['countryCode']
      );
      this.getBusinessUnitGid(
        data['userInfo']['userRestrictions'][0]['countryCode']
      );
    });
    // this.searchCondition.businessUnitGid = this.userService.getCountryId();

    this.mapService.initMapMarker('truck_number_map', '');
    this.columnDef = this.getColumnDef();
  }

  public queryUserInfo() {
    return this.http$
      .post(
        '/htms/api/user/sce/getUserInfo',
        JSON.stringify({
          key: JSON.parse(localStorage.getItem('jwt_token')).token,
        })
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getBusinessUnitGid(value) {
    let params: HttpParams = new HttpParams();
    this.http$
      .get(tmsConfig.tms.getBusinessUnitGid + generateGid(value), {
        params: params,
      })
      .subscribe((res: any) => {
        this.Gid = res;
        this.searchCondition.businessUnitGid = this.Gid;
        this.search();
      });
  }
  getColumnDef() {
    return [
      {
        elementId: this.resourceService.get('lbl_tms_truck_number'),
        propertyName: 'carNumber',
      },
      {
        elementId: this.resourceService.get('lbl_tms_driver_name'),
        propertyName: 'driverName',
      },
      {
        elementId: this.resourceService.get('lbl_tms_truck_type'),
        propertyName: 'equipmentGroupName',
      },
      {
        elementId: this.resourceService.get('lbl_tms_carrier_code'),
        propertyName: 'servprovXid',
      },
      {
        elementId: this.resourceService.get('lbl_tms_carrier_name'),
        propertyName: 'servprovName',
      },
      {
        elementId: this.resourceService.get('lbl_tms_fleet_quota'),
        propertyName: 'attribute1',
      },
      {
        elementId: this.resourceService.get('lbl_tms_effective_weight'),
        propertyName: 'effectiveWeight',
      },
      {
        elementId: this.resourceService.get('lbl_tms_effective_volume'),
        propertyName: 'effectiveVolume',
      },
      {
        elementId: this.resourceService.get('lbl_tms_status'),
        propertyName: 'status',
      },
    ];
  }

  search() {
    let params: HttpParams = new HttpParams();
    Object.keys(this.searchCondition).forEach(k => {
      if (this.searchCondition[k]) {
        params = params.set(k, this.searchCondition[k]);
      }
    });
    params = params
      .set('page', this.pageInfo.number || 0)
      .set('size', this.pageInfo.size || 10);

    this.http$
      .post(tmsConfig.tms.equipmentQueryUri, this.searchCondition, {
        params: params,
      })
      .toPromise()
      .then(data => {
        this.pageInfo = data;
        this.refreshMap();
      });
  }

  private refreshMap() {
    const points = [];
    this.pageInfo.content.forEach((row: any) => {
      points.push([row.sightingLon, row.sightingLat]);
    });
    this.mapService.refreshMapMarker(points);
  }

  onConfirm() {
    this.paramsService.truckNumDate = this.selectedRow;
    this.selectedRow.buttonType = 'confirm';
    if (this.paramsService.onlyShow || this.paramsService.onlyShow2) {
      this.ref.close(this.selectedRow);
    } else {
      this.ref.close(TruckNumberDetailComponent);
    }

    // this.confirmHandler=this.paramsService.confirmHandler;
    // this.confirmHandler2=this.paramsService.confirmHandler2;
    // if (this.confirmHandler) {
    //   this.confirmHandler(this.selectedRow).then((res: any) => {
    //     if (res.success) {
    //       this.alertService.clearAll().success(res.message);
    //       this.ref.close(TruckNumberDetailComponent)
    //       this.searchService.performSearch();
    //     } else {
    //       this.alertService.clearAll().error(res.message);
    //     }
    //   });
    // }

    // if (this.confirmHandler2) {
    //   this.confirmHandler2({
    //     equipmentGid: this.selectedRow.id,
    //     equipmentName: this.selectedRow.name,
    //     equipmentXid: this.selectedRow.xid,
    //     equipmentGroupGid: this.selectedRow.equipmentGroupGid,
    //     equipmentGroupXid: this.selectedRow.equipmentGroupXid,
    //     equipmentGroupName: this.selectedRow.equipmentGroupName,
    //     equipmentNumber: this.selectedRow.carNumber,
    //     driverName: this.selectedRow.driverName,
    //     driverPhone: this.selectedRow.driverPhone,
    //     driverXid: this.selectedRow.driverXid,
    //     driverGid: this.selectedRow.driverGid,
    //     email: this.selectedRow.email,
    //   });
    // }

    // if (this.confirmHandler3) {
    //   this.confirmHandler3(this.selectedRow);
    // }
  }

  onCancel() {
    this.ref.close(TruckNumberDetailComponent);
    // if (this.cancelHandler) {
    //   this.cancelHandler();
    // } else {
    //   this.modalService.modalReference.hide();
    // }
  }

  onPageChange($event: any) {
    this.pageInfo.number = this.paginator.getPage();
    this.pageInfo.size = this.paginator.rows;
    this.search();
  }

  onRowClick(e: any) {
    this.selectedRow = this.selectedRow === e.data ? null : e.data;
  }

  updateFilter() {
    setTimeout(() => {
      this.search();
    }, 200);
  }
}
