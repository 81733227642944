import { GridOptions, RowNode } from '@ag-grid-community/all-modules';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  throwError as observableThrowError,
  of,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlertService, ResourceService, UserService } from 'sce-core';
import { metaDataChildGrpTable } from 'src/app/features/notification/child-template/child-template-config';
import { metaDataMasterGrpTable } from 'src/app/features/notification/master-template/master-template-config';
import { metaDataMassGrpTable } from 'src/app/features/order/mass-upload/mass-upload-config';
import { metaDataOrderGrpTable } from 'src/app/features/order/order-event/order-event-config';
import { DateUtil } from 'src/app/features/reserve/common/utils/date-util';
import { metaShipmentPlanningDataGrp } from 'src/app/features/shipment/shipment-planning/shipment-planning-config';
import { UserPreferenceService } from '../../layout/home/services/user-preference.service';
import { DatePickerConfigService } from '../components/datepicker/datepicker-config.service';
import { LoaderEnabled } from '../components/loader/loader.service';
import { generateGid } from '../services/business-unit-gid.util';
import { LocalPagedDataManager } from './local-paged-data-manager.service';
import { PageDefinition } from './models/page-definition.model';
import { PagedData } from './models/paged-data.model';
import { Preference } from './models/preference.model';
import { SavedSearchConfig } from './models/saved-search-config';
import { SearchEvent } from './models/search-event';
import { Condition } from './models/search-filter-model';
import { SearchParams } from './models/search-params.model';
import { SearchStatus } from './models/search-status.model';
import { TablePreference } from './models/table-preference.model';
import { TableRow } from './models/table-row.model';
import {
  DELETE_ROW_EVENT,
  DUPLICATE_ROW_EVENT,
  INSERT_ROW_EVENT,
  NEW_ROW_EVENT,
  NEW_SEARCH_EVENT,
} from './search-constants';
import { addTimeZoneHeader } from '../helpers/http.helpers';

@Injectable()
export class SearchService {
  // This object represents a searchable data table in the client side. It contains the corresponding attributes and methods

  // The metadata corresponding to the data table which this object is representing
  public metadata: any;
  public searchId: any;
  public searchUrl: string; // lookup SuggestionUrl

  // The object which contains the data of the current page
  public pagedData: PagedData<TableRow>;

  // The search criteria to be applied to fetch records from the backend
  public searchCriteria: any;

  public filterCondition: Condition;

  public filterStatus: any;
  public gridOptions: GridOptions;

  public dataManager: LocalPagedDataManager;

  // The subject which will send out search related events
  public searchEventSubject = new Subject<SearchEvent>();

  public savedSearchConfig: SavedSearchConfig = new SavedSearchConfig();

  // Flag indicating that a page overflow event is in progress
  public newPageEventInProgress: boolean = false;

  /** Possible Values for displayMode input ::
   *  [C] - Create
   *  [E] - Edit
   *  [V] - View (Default)
   */
  public displayMode: string = 'V';

  public tagPopup = false;

  // Non Mandatory Conditions Count
  public nonMandatoryConditionsCount: number = 0;

  public tablePreference: any;

  // The subject which will send out listeners to the metadata fetch
  public metaDataFetchSubject: ReplaySubject<any>;

  // Variable to check whether call is coming to performSearch from pagination or not
  public isFromPageChange: Boolean = false;

  // variable to store full data in case of a locally paged table
  public fullData: any[] = [];
  //  this property is used in screens with multiple table like invbalances
  public tableIdforTablePref: string;
  // This property is used to get tableId from searchService, where we don't have dataService
  public tableIdforSearch: string;
  public nonEdiablePrimaryKey: any;
  public entityConfigMetaData: any;
  public isSearchFilterInitialized: boolean = false;
  public additionalParamtoFetchDetail: Array<any> = [];
  public autoSuggestionList: any = [];
  // public suggestionListForOptionsModel = [];
  public optionsModel: number[];
  public selectedRows: any;
  public instructionForNextSearch = new BehaviorSubject('');
  public instructionForLegSearch = new BehaviorSubject('');
  public componentName = '';
  public instructionForMultiSelect = new BehaviorSubject('');
  public multiSelectDropdownListForShipment = [];
  public multiSelectDropdownListForShipperConsignee = [];
  public multiSelectDropdownListForShipperConsigneePopup = [];
  public multiSelectDropdownListForOrder = [];
  public multiSelectDropdownListForSailingShipment = [];
  public multiSelectDropdownListForSailingLegShipment = [];
  public multiSelectDropdownListForPO = [];
  public suggestionListForOptionsModelForShipment = [];
  public suggestionListForOptionsModelForShipperConsignee = [];
  public suggestionListForOptionsModelForShipperConsigneePopup = [];
  public suggestionListForOptionsModelForOrder = [];
  public suggestionListForOptionsModelForSailingShipment = [];
  public suggestionListForOptionsModelForSailingLegShipment = [];
  public suggestionListForOptionsModelForPO = [];
  public columnListOrder = [];
  public bulkUpdateButtons = [];
  public inputOptionsForAppcode = [];
  public inputOptionsForTag = [];
  public hdr = new HttpHeaders({ api: 'admin' });
  public populateLegData: TableRow[];
  public allSearchTableData;
  public isMultiLegSelection = false;
  public isNewrows = false;
  public isPopup = false;
  public isShow: boolean;
  public allSearchData;
  searchData: any;
  constructor(
    public http$: HttpClient,
    public searchParams: SearchParams,
    public searchStatus: SearchStatus,
    public resourceService: ResourceService,
    public userPreferenceService: UserPreferenceService,
    public alertService: AlertService,
    public dateConfigService: DatePickerConfigService,
    public userService: UserService
  ) {
    this.pagedData = new PagedData<TableRow>();
  }

  public getSavedSearchConfig(): SavedSearchConfig {
    return this.savedSearchConfig;
  }

  public clearSavedSearchConfig() {
    this.savedSearchConfig = new SavedSearchConfig();
  }

  public fetchMetadataNotifDtl(): Observable<any> {
    return this.metaDataFetchSubject.asObservable();
  }

  /**
   * This method will fetch the metadata for the search entity. Internally it will also fetch the
   * table preferences set by the user, if any
   */
  public fetchMetadata(dshbCategory?: any, dashboard?: any): Observable<any> {
    if (!this.metaDataFetchSubject) {
      this.metaDataFetchSubject = new ReplaySubject<any>(1);
      // if a metadata fetch is not in progress, create a new observable. Else return the already active one
      let tableId = this.searchParams.tableId;
      this.tableIdforSearch = tableId;
      if (window.location.href.includes('shipment-planning')) {
        this.metadata = metaShipmentPlanningDataGrp;
      } else if (window.location.href.includes('order-event')) {
        this.metadata = metaDataOrderGrpTable;
      } else if (window.location.href.includes('mass-upload')) {
        this.metadata = metaDataMassGrpTable;
      } else if (window.location.href.includes('master-template')) {
        this.metadata = metaDataMasterGrpTable;
      } else if (window.location.href.includes('child-template')) {
        this.metadata = metaDataChildGrpTable;
      }
      // for screens like inv balances that have multiple tables in a single screen
      // we set tableprefernces for a single table.So if we provide the value for this variable then alwayas for any table
      //  in that screen the table preferneces coresponding  to this.tableIdforTablePref will be fetched else
      //  the tablepreferences coresponding to tableId will be fetched
      this.tableIdforTablePref = this.tableIdforTablePref
        ? this.tableIdforTablePref
        : tableId;
      tableId = this.tableIdforTablePref;
      // Lets fetch the table preferences asynchronously, so that the preferences are available along with the meta data
      const tablePreferenceFetch = this.http$
        .get(`api/core/user/tablepreferences/` + tableId + '/', {
          headers: this.hdr,
        })
        .pipe(
          map((response: any) => {
            return this.transformTablePreference(tableId, response);
          }),
          catchError((error: any) => {
            // Lets not stop navigation. Continue as if there is no preference set
            return of(new TablePreference());
          })
        );
    }
    return this.metaDataFetchSubject.asObservable();
  }

  public getMetadata(): any {
    return this.metadata;
  }
  // public fetchLastSearch(): any {
  //   //  return this.lastSearch ;
  //   }
  public setMetadata(metadata: any) {
    this.metadata = metadata;
  }

  public getPagedData(): PagedData<any> {
    return this.pagedData;
  }

  public setPagedRows(rows: any) {
    this.pagedData.pageRows = rows;
  }

  public getSearchCriteria(): any {
    return this.searchCriteria;
  }

  public setFilterStatus(filterStatus: any) {
    this.filterStatus = filterStatus;
  }

  public getFilterStatus(): any {
    return this.filterStatus;
  }

  public setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  public getItem(key) {
    return JSON.parse(localStorage.getItem(key));
  }
  public removeItem(key) {
    localStorage.removeItem(key);
  }

  public setDataManager(dataManager) {
    this.dataManager = dataManager;
  }

  public setSearchCriteria(searchCriteria: any) {
    this.searchCriteria = searchCriteria;
  }

  public getFilterCondition(): Condition {
    return this.filterCondition;
  }

  public setFilterCondition(filterCondition: Condition) {
    this.filterCondition = filterCondition;
  }

  public getIsFromPageChange() {
    return this.isFromPageChange;
  }

  public setIsFromPageChange(isFromPageChange: Boolean) {
    this.isFromPageChange = isFromPageChange;
  }

  public getTablePreference() {
    if (!this.tablePreference) {
      this.tablePreference = new TablePreference();
    }
    return this.tablePreference;
  }

  public getNonMandatoryConditionsCount(): any {
    return this.nonMandatoryConditionsCount;
  }

  public setNonMandatoryConditionsCount(nonMandatoryConditionsCount: any) {
    this.nonMandatoryConditionsCount = nonMandatoryConditionsCount;
  }

  // Returns currently focused row and columnName
  public getFocusedRow(): { row: TableRow; cName: string } {
    return this.pagedData.getFocusedRow();
  }

  // Returns currently selected rows by checkbox value
  public getSelectedRows(): Array<any> {
    return this.pagedData.getSelectedRows();
  }

  public getNonSelectedRows(): Array<any> {
    return this.pagedData.getNonSelectedRows();
  }
  public getAllRows(): Array<any> {
    return this.pagedData.getAllRows();
  }

  // Returns edited data<only edited cells per row>
  public getEditedCellsPerRow(mandatoryProps?): any[] {
    return this.pagedData.getEditedCellsPerRow(mandatoryProps);
  }

  public getEditedRows(): TableRow[] {
    return this.pagedData.getEditedRows();
  }

  // Clear markup flag<>
  /* LFWM-960 Removing the previous skip first clearing code */
  public clearMarkerFlags(): void {
    this.pagedData.clearMarkerFlags();
  }

  /**
   * This method clears the data from the search service
   */
  public clearSearchStatus() {
    this.searchStatus.hasResults = false;
    this.searchStatus.recordCount = 0;
    this.pagedData.pageRows.splice(0);
  }

  public queryUserInfo() {
    return this.http$
      .post(
        '/htms/api/user/sce/getUserInfo',
        JSON.stringify({
          key: JSON.parse(localStorage.getItem('jwt_token')).token,
        })
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**
   * This method invokes the search method asynchronously and sends a NEW_SEARCH event once complete.
   * This method can be used by methods which needs to trigger a search call, but do not need to handle
   * the response explicitly
   */
  public performSearch() {
    let data: any = [];
    let isMandatoryfieldSelected = true;
    let errorMsg = 'Please select filter(s): ';
    if (this.searchParams.tableId === 'dshbdsearchtd') {
      this.metadata.columnList.forEach(mData => {
        if (mData.isMandatory) {
          let isConditionAdded = false;
          this.searchCriteria.conditions.forEach((condition, index) => {
            condition.clauses.forEach(clause => {
              if (
                clause.column === mData.propertyName &&
                clause.value !== '' &&
                clause.value !== '*' &&
                clause.value !== 'No Restriction'
              ) {
                isConditionAdded = true;
              }
            });
          });
          if (!isConditionAdded) {
            isMandatoryfieldSelected = false;
            errorMsg += this.resourceService.get(mData.elementId) + ' ';
            return;
          }
        }
      });
      if (!isMandatoryfieldSelected) {
        this.alertService.clearAll().error(errorMsg);
        return;
      }
      this.searchStatus.hasResults = true;
      this.searchStatus.recordCount = this.pagedData.pageDefinition.recordCount;
      const event = new SearchEvent(NEW_SEARCH_EVENT);
      event.isSearchSuccess = true;
      this.publishEvent(event);
    } else {
      this.fetchSearchResults().subscribe((data: any) => {
        this.searchStatus.hasResults = false;
        this.searchStatus.recordCount =
          this.pagedData.pageDefinition.recordCount;
        const event = new SearchEvent(NEW_SEARCH_EVENT);
        event.isSearchSuccess = true;
        this.publishEvent(event);
        this.searchData = data;
      });
    }
  }

  public formatDate(datetime) {
    var date = new Date(datetime);
    var YY = date.getFullYear() + '-';
    var MM =
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) + '-';
    var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var hh =
      (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm =
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':';
    var ss =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    //  return YY + MM + DD +" "+hh + mm + ss;
    let formatDates = moment(
      date,
      'YYYY-MM-DD' + ' ' + 'HH:mm:ss.SSS',
      true
    ).format('YYYY-MM-DD' + ' ' + 'HH:mm:ss.SSS');

    return formatDates;
  }
  UTCTime(date) {
    if (date) {
      var localDate = new Date(date); // 请根据需要替换为你的本地时间
      var utcDate = localDate.toLocaleString('en-US', {
        timeZone: 'UTC',
        hour12: false,
      });
      // let dates =  new Date(utcDate)
      // let formattedTime = dates.toISOString().replace("T", " ").replace("Z", "");
      console.log('UTC date: ' + utcDate);
      // console.log("UTC date: " + formattedTime);
      let dateParts = utcDate.split(', ')[0].split('/');
      let timePart = utcDate.split(', ')[1];
      if (timePart.startsWith('24')) {
        timePart = '00' + timePart.slice(2);
      }
      let year = dateParts[2];
      let month = dateParts[0].padStart(2, '0');
      let day = dateParts[1].padStart(2, '0');

      let formattedDate = `${year}-${month}-${day} ${timePart}.000`;
      console.log(formattedDate);
      return formattedDate;
    } else {
      return;
    }
  }
  public queryData(value: string) {
    var searchConditions = [];
    var startTime: any = new Date(new Date().setHours(0, 0, 0, 0));
    var endTime: any = new Date(new Date().setHours(23, 59, 59, 0));

    var SevenDayAgo = startTime - 86400 * 7 * 1000;
    var SevenDayLater = endTime * 1 + 86400 * 7 * 1000;
    if (value === 'init') {
      if (
        [
          'shpmtsearchEX',
          'shipmtfinalizedtd',
          'shpmtsearchtd',
          'shpmtAddPack',
        ].includes(this.searchParams.tableId)
      ) {
        searchConditions.push({
          columnName: 'Shipment Type',
          operation: '=',
          varcharMatch: 'P',
        });
        searchConditions.push({
          columnName: 'End Time',
          operation: 'between',
          dateMin: this.formatDate(SevenDayAgo),
          dateMax: this.formatDate(SevenDayLater),
        });
      }
      if (['shpmtsearchtd'].includes(this.searchParams.tableId)) {
        searchConditions.push({
          columnName: 'Shipment Status',
          operation: '=',
          varcharMatch: 'OPEN',
        });
      }
      if (['shipmtfinalizedtd'].includes(this.searchParams.tableId)) {
        searchConditions.push({
          columnName: 'Shipment Status',
          operation: '=',
          varcharMatch: 'FINALIZED',
        });
      }
      if (['shpmtsearchEX'].includes(this.searchParams.tableId)) {
        searchConditions.push({
          columnName: 'Shipment Status',
          operation: 'NOT IN',
          varcharMatch: 'OPEN',
        });
      }
    } else {
      if (this.searchCriteria?.conditions.length > 0) {
        if (
          [
            'shpmtsearchEX',
            'shipmtfinalizedtd',
            'shpmtsearchtd',
            'shpmtAddPack',
          ].includes(this.searchParams.tableId)
        ) {
          searchConditions.push({
            columnName: 'Shipment Type',
            operation: '=',
            varcharMatch: 'P',
          });
        }
        if (['shpmtsearchtd'].includes(this.searchParams.tableId)) {
          searchConditions.push({
            columnName: 'Shipment Status',
            operation: '=',
            varcharMatch: 'OPEN',
          });
        }
        if (['shipmtfinalizedtd'].includes(this.searchParams.tableId)) {
          searchConditions.push({
            columnName: 'Shipment Status',
            operation: '=',
            varcharMatch: 'FINALIZED',
          });
        }
        if (['shpmtsearchEX'].includes(this.searchParams.tableId)) {
          searchConditions.push({
            columnName: 'Shipment Status',
            operation: 'NOT IN',
            varcharMatch: 'OPEN',
          });
        }
        this.searchCriteria.conditions.forEach((item: any, index: number) => {
          item.clauses.forEach((conditions: any) => {
            if (conditions.value) {
              if (['massUploadsearchtd'].includes(this.searchParams.tableId)) {
                if (conditions.typeOfField === 'DATETIME') {
                  searchConditions.push({
                    columnName: this.metadata['columnList'].filter(
                      value => value.propertyName == conditions.column
                    )[0]['cellDataName'],
                    operation: conditions.operation,
                    dateMin: this.UTCTime(conditions.value),
                    dateMax: this.UTCTime(conditions.value1),
                  });
                } else {
                  searchConditions.push({
                    columnName: this.metadata['columnList'].filter(
                      value => value.propertyName == conditions.column
                    )[0]['cellDataName'],
                    operation: conditions.operation,
                    varcharMatch: conditions.value,
                  });
                }
              } else {
                if (
                  conditions.typeOfField === 'DATETIME' ||
                  conditions.typeOfField === 'DATE'
                ) {
                  searchConditions.push({
                    columnName: conditions.column,
                    operation: conditions.operation,
                    dateMin: this.UTCTime(conditions.value),
                    dateMax: this.UTCTime(conditions.value1),
                  });
                } else {
                  searchConditions.push({
                    columnName: conditions.column,
                    operation: conditions.operation,
                    varcharMatch: conditions.value,
                  });
                }
              }
            }
          });
        });
      }
    }
    console.log(searchConditions);

    if (searchConditions.length === 0) {
      if (
        [
          'shpmtsearchEX',
          'shipmtfinalizedtd',
          'shpmtsearchtd',
          'shpmtAddPack',
        ].includes(this.searchParams.tableId)
      ) {
        searchConditions.push({
          columnName: 'Shipment Type',
          operation: '=',
          varcharMatch: 'P',
        });
        searchConditions.push({
          columnName: 'End Time',
          operation: 'between',
          dateMin: this.formatDate(SevenDayAgo),
          dateMax: this.formatDate(SevenDayLater),
        });
      }

      if (['shpmtsearchtd'].includes(this.searchParams.tableId)) {
        searchConditions.push({
          columnName: 'Shipment Status',
          operation: '=',
          varcharMatch: 'OPEN',
        });
      }
      if (['shipmtfinalizedtd'].includes(this.searchParams.tableId)) {
        searchConditions.push({
          columnName: 'Shipment Status',
          operation: '=',
          varcharMatch: 'FINALIZED',
        });
      }
      if (['shpmtsearchEX'].includes(this.searchParams.tableId)) {
        searchConditions.push({
          columnName: 'Shipment Status',
          operation: 'NOT IN',
          varcharMatch: 'OPEN',
        });
      }
    }

    var userinfo = JSON.parse(localStorage.getItem('user_info'));
    var countryKey = generateGid(
      userinfo['userRestrictions'][0]['countryCode']
    );

    console.debug('countryKey', countryKey);

    //获取 locationID/carrier/principal,permissionAddUrl为locationID/carrier/principal的拼接
    var permission = JSON.parse(localStorage.getItem('tms_button_permission'));
    var locationId =
      permission['userRestrictions'][0]?.restrictions.filter(
        item => item.restrictionTypeName == 'Location ID'
      )[0]?.restrictionValue || null;
    var carrierId =
      permission['userRestrictions'][0]?.restrictions.filter(
        item => item.restrictionTypeName == 'Carrier'
      )[0]?.restrictionValue || null;
    var principalId =
      permission['userRestrictions'][0]?.restrictions.filter(
        item => item.restrictionTypeName == 'Principal'
      )[0]?.restrictionValue || null;
    var tenantId =
      permission['userRestrictions'][0]?.restrictions.filter(
        item => item.restrictionTypeName == 'Tenant ID'
      )[0]?.restrictionValue || 0;

    //如果返回tenantId那么所有接口都要拼tenantId
    var permissionAddUrl = `&tenantID=${tenantId}`;

    //有些接口需要加入locationID/carrier/principal，则须在url查询字符串加入拼接的路径，对其他url不产生影响，如果需要拼接则在下面flag加入对应tableId
    var permissionIdFlag = [
      'orderVisibilitysearchtd',
      'orderProcessingsearchtd',
      'orderEventsearchtd',
      'massUploadsearchtd',
      'allOrdersearchtd',
      'shpmtsearchtd',
      'shpmtsearchEX',
      'shipmtfinalizedtd',
    ].includes(this.searchParams.tableId);

    //如果可以获取id则拼入，反之则不拼
    if (permissionIdFlag) {
      //ordprocessing、massupload、allord需要locationId
      if (
        [
          'orderVisibilitysearchtd',
          'orderProcessingsearchtd',
          'massUploadsearchtd',
          'allOrdersearchtd',
        ].includes(this.searchParams.tableId)
      ) {
        if (locationId) {
          permissionAddUrl += `&locationID=${locationId}`;
        }
      }
      //ordprocessing、massupload、allord、ordevent需要principalId
      if (
        [
          'orderVisibilitysearchtd',
          'orderProcessingsearchtd',
          'orderEventsearchtd',
          'massUploadsearchtd',
          'allOrdersearchtd',
        ].includes(this.searchParams.tableId)
      ) {
        if (principalId) {
          permissionAddUrl += `&principal=${principalId}`;
        }
      }
      //flag为true页面都需要carrierId
      if (carrierId) {
        permissionAddUrl += `&carrier=${carrierId}`;
      }
    }
    localStorage.setItem('permissionAddUrl', permissionAddUrl);

    let response$: any;

    //有些页面初始参数为对象格式，如果需要为对象格式，则可在下面Flag加入tableId，如果不加入则默认通用查询的数组传值方式
    var countryParamsFlag = [
      'orderVisibilitysearchtd',
      'reasonCodesearchtd',
      'commoditysearchtd',
      'safetyChecksearchtd',
      'transportHandlingsearchtd',
      'serverConfigurationsearchtd',
      'viewMessagessearchtd',
      'manageWorkflowsearchtd',
      'paramcfgsearchtd',
      'blackbindingsearchtd',
      'blackdashboardsearchtd',
      'blackmanagementsearchtd',
    ].includes(this.searchParams.tableId);

    //对象格式参数的个性化，例如status&reacon Code页面需要初始需要传入countryKey
    var countryParams = {};
    if (
      [
        'reasonCodesearchtd',
        'commoditysearchtd',
        'safetyChecksearchtd',
        'transportHandlingsearchtd',
        // 'viewMessagessearchtd',
        'childTemplatesearchtd',
        'masterTemplatesearchtd',
        'manageEventsearchtd',
      ].includes(this.searchParams.tableId)
    ) {
      countryParams = {
        countryKey,
      };
    } else if (
      ['serverConfigurationsearchtd'].includes(this.searchParams.tableId)
    ) {
      countryParams = searchConditions;
    }
    var userinfo = JSON.parse(localStorage.getItem('user_info'));
    console.debug('userinfo', userinfo.userGroupCodes);
    let authority = true;
    userinfo?.userGroupCodes.forEach(ele => {
      if (ele == 'TMS-Carrier') {
        authority = false;
      }
    });
    //第一个if如果传国家字段
    if (countryParamsFlag) {
      console.debug(countryKey);
      console.debug(searchConditions);

      // 接口数据更改
      if (['orderVisibilitysearchtd'].includes(this.searchParams.tableId)) {
        response$ = this.http$
          .post(
            this.searchUrl +
              '/' +
              countryKey +
              '?page=0' +
              '&size=10' +
              permissionAddUrl +
              '&insertDateFromStr=' +
              DateUtil.formatDateYMD(new Date().getTime() - 1209600000) +
              '&insertDateToStr=' +
              DateUtil.formatDateYMD(new Date()),
            JSON.parse(JSON.stringify(searchConditions))
          )
          .pipe(
            map((response: any) => {
              this.filterStatus = response;
              return response;
            }),
            catchError((error: any) => {
              return this.resourceService.get(error.message);
            })
          );
      } else if (['viewMessagessearchtd'].includes(this.searchParams.tableId)) {
        response$ = this.http$
          .post(
            this.searchUrl +
              '/' +
              countryKey +
              '?page=0' +
              '&size=10' +
              permissionAddUrl,
            JSON.stringify(searchConditions)
          )
          .pipe(
            map((response: any) => {
              this.filterStatus = response;
              return response;
            }),
            catchError((error: any) => {
              return this.resourceService.get(error.message);
            })
          );
      } else if (
        ['manageWorkflowsearchtd'].includes(this.searchParams.tableId)
      ) {
        response$ = this.http$
          .post(this.searchUrl + '?page=0' + '&size=10', JSON.stringify({}))
          .pipe(
            map((response: any) => {
              return response;
            }),
            catchError((error: any) => {
              return this.resourceService.get(error.message);
            })
          );
      } else if (['paramcfgsearchtd'].includes(this.searchParams.tableId)) {
        response$ = this.http$
          .get(this.searchUrl + '?page=0' + '&size=10' + '&type=ETA')
          .pipe(
            map((response: any) => {
              return response;
            }),
            catchError((error: any) => {
              return this.resourceService.get(error.message);
            })
          );
      } else if (['blackbindingsearchtd'].includes(this.searchParams.tableId)) {
        response$ = this.http$
          .post(
            this.searchUrl + '?page=0' + '&size=10',
            JSON.stringify({ status: 'F' })
          )
          .pipe(
            map((response: any) => {
              this.filterStatus = response;
              return response;
            }),
            catchError((error: any) => {
              return this.resourceService.get(error.message);
            })
          );
      } else if (
        ['blackdashboardsearchtd'].includes(this.searchParams.tableId)
      ) {
        response$ = this.http$
          .post(
            this.searchUrl + '?page=0' + '&size=10',
            JSON.stringify({ timeUnits: [0], notification: [], colorName: [] })
          )
          .pipe(
            map((response: any) => {
              this.filterStatus = response;
              return response;
            }),
            catchError((error: any) => {
              return this.resourceService.get(error.message);
            })
          );
      } else if (
        ['blackmanagementsearchtd'].includes(this.searchParams.tableId)
      ) {
        response$ = this.http$
          .get(this.searchUrl + '&page=0' + '&size=10')
          .pipe(
            map((response: any) => {
              this.filterStatus = response;
              return response;
            }),
            catchError((error: any) => {
              return this.resourceService.get(error.message);
            })
          );
      } else if (['shpmtAddPack'].includes(this.searchParams.tableId)) {
        response$ = this.http$
          .post(
            this.searchUrl +
              '?page=0' +
              '&size=10' +
              permissionAddUrl +
              '&shpmtAddPack=true',
            JSON.stringify(countryParams)
          )
          .pipe(
            map((response: any) => {
              this.filterStatus = response;
              return response;
            }),
            catchError((error: any) => {
              return this.resourceService.get(error.message);
            })
          );
      } else {
        response$ = this.http$
          .post(
            this.searchUrl + '?page=0' + '&size=10' + permissionAddUrl,
            JSON.stringify(countryParams)
          )
          .pipe(
            map((response: any) => {
              this.filterStatus = response;
              return response;
            }),
            catchError((error: any) => {
              return this.resourceService.get(error.message);
            })
          );
      }
    } else if (['massUploadsearchtd'].includes(this.searchParams.tableId)) {
      response$ = this.http$
        .post(
          this.searchUrl +
            '/' +
            countryKey +
            '?page=0' +
            '&size=10' +
            permissionAddUrl,
          JSON.stringify(searchConditions)
        )
        .pipe(
          map((response: any) => {
            this.filterStatus = response;
            return response;
          }),
          catchError((error: any) => {
            return this.resourceService.get(error.message);
          })
        );
    } else if (['masterTemplatesearchtd'].includes(this.searchParams.tableId)) {
      response$ = this.http$
        .post(
          this.searchUrl +
            '/' +
            countryKey +
            '?page=0' +
            '&size=10' +
            '&isMaster=1',
          JSON.stringify(searchConditions)
        )
        .pipe(
          map((response: any) => {
            this.filterStatus = response;
            return response;
          }),
          catchError((error: any) => {
            return this.resourceService.get(error.message);
          })
        );
    } else if (['childTemplatesearchtd'].includes(this.searchParams.tableId)) {
      response$ = this.http$
        .post(
          this.searchUrl +
            '/' +
            countryKey +
            '?page=0' +
            '&size=10' +
            '&isMaster=0',
          JSON.stringify(searchConditions)
        )
        .pipe(
          map((response: any) => {
            this.filterStatus = response;
            return response;
          }),
          catchError((error: any) => {
            return this.resourceService.get(error.message);
          })
        );
    } else if (['manageEventsearchtd'].includes(this.searchParams.tableId)) {
      response$ = this.http$
        .post(
          this.searchUrl + '?page=0' + '&size=10',
          JSON.stringify(searchConditions)
        )
        .pipe(
          map((response: any) => {
            this.filterStatus = response;
            return response;
          }),
          catchError((error: any) => {
            return this.resourceService.get(error.message);
          })
        );
    } else if (['orderEventsearchtd'].includes(this.searchParams.tableId)) {
      response$ = this.http$
        .post(
          this.searchUrl +
            '/' +
            countryKey +
            '?page=0' +
            '&size=10' +
            '&authority=' +
            authority +
            permissionAddUrl,
          JSON.stringify(searchConditions)
        )
        .pipe(
          map((response: any) => {
            this.filterStatus = response;
            return response;
          }),
          catchError((error: any) => {
            return this.resourceService.get(error.message);
          })
        );
    } else if (['shpmtAddPack'].includes(this.searchParams.tableId)) {
      response$ = this.http$
        .post(
          this.searchUrl +
            '/' +
            countryKey +
            '?page=0' +
            '&size=10' +
            '&shpmtAddPack=true' +
            permissionAddUrl,
          JSON.stringify(searchConditions)
        )
        .pipe(
          map((response: any) => {
            this.filterStatus = response;
            return response;
          }),
          catchError((error: any) => {
            return this.resourceService.get(error.message);
          })
        );
    } else if (this.searchParams.tableId === 'pullReportSearchId') {
      response$ = this.http$
        .post(
          `${this.searchUrl}${countryKey}/search?page=0&size=10${permissionAddUrl}`,
          JSON.stringify(searchConditions),
          {
            headers: addTimeZoneHeader(),
          }
        )
        .pipe(
          map((response: any) => {
            this.filterStatus = response;
            return response;
          }),
          catchError((error: any) => {
            return this.resourceService.get(error.message);
          })
        );
    } else {
      response$ = this.http$
        .post(
          this.searchUrl +
            '/' +
            countryKey +
            '?page=0' +
            '&size=10' +
            permissionAddUrl,
          JSON.stringify(searchConditions)
        )
        .pipe(
          map((response: any) => {
            this.filterStatus = response;
            return response;
          }),
          catchError((error: any) => {
            return this.resourceService.get(error.message);
          })
        );
    }
    return response$;
  }

  /**
   * This method returns an observable to the search request.
   * This method can be used by methods which needs to explicitly handle the response.
   * This method however does not send a NEW_SEARCH event to the listeners
   */
  @LoaderEnabled()
  public fetchSearchResults(): Observable<Response> {
    this.pagedData.pageRows = [];
    const pageInfoReq = {
      tableId: 'printjobtd',
      // tableId: tableId,
      pageNo: this.pagedData.pageDefinition.pageNumber,
      sortColumn: this.pagedData.pageDefinition.sortColumn,
      sortOrder: this.pagedData.pageDefinition.sortOrder,
      pageSize: this.pagedData.pageDefinition.pageSize,
      fullFetch: this.searchParams.isFullFetch,
      fetchAllrecords: this.searchParams.paginateLocally,
      fetchArchiveRecords: this.searchParams.fetchArchiveRecords,
    };
    const payload = {
      pageInfo: pageInfoReq,
      searchCriteria: { conditions: [] },
      // this.formatSearchCriteria(this.searchCriteria, this.searchParams.additionalParamMap, this.filterCondition)
    };
    // const dataUrl$: any
    //   = this.http$.post(`api/data/` + 'printjobtd'
    //     + `/loadByPage`, JSON.stringify(payload));

    const dataUrl$: any = this.http$.get(this.searchUrl);
    return dataUrl$.pipe(
      map((response: any) => {
        return this.transformSearchResponse(response);
      }),
      catchError((error: any) => {
        // JIRA LFWM - 1716 When the user provides any invalid filter value then the previous result data should be cleared
        this.pagedData.pageDefinition.recordCount = 0;
        this.pagedData.pageRows.splice(0, 0);
        this.searchStatus.hasResults = true;
        this.searchStatus.recordCount = 0;
        const event = new SearchEvent(NEW_SEARCH_EVENT);
        event.isSearchSuccess = false;
        this.publishEvent(event);
        return observableThrowError(error);
      })
    );
  }

  /**
   * Transforms the search criteria to a server specific format
   *
   * @param searchCriteria - The criteria to be searched
   * @param additionalParamMap - Map of additional parameters to be set in the request
   */
  public formatSearchCriteria(
    searchCriteria,
    additionalParamMap,
    filterCondition?: any
  ) {
    if (!searchCriteria) {
      return {};
    }
    let allConditions = null;
    // Code added to merge filter conditions with master search criteria, if filter condition is there.
    if (filterCondition && filterCondition.clauses.length > 0) {
      allConditions = JSON.parse(JSON.stringify(searchCriteria.conditions));
      if (allConditions && allConditions.length > 0) {
        allConditions[allConditions.length - 1].operator = 'AND';
      }
      allConditions.push(filterCondition);
    } else {
      allConditions = searchCriteria.conditions;
    }
    const conditionReqList = [];
    allConditions.forEach((condition: any, index: any) => {
      const clauseReqlist = [];
      condition.clauses.forEach((clause: any) => {
        if (clause.value !== undefined && clause.value !== null) {
          clauseReqlist.push({
            column: clause.column,
            operation: clause.operation,
            value: clause.value,
            value1: clause.value1,
            logicalOperation: clause.logicalOperation,
            typeOfField: clause.typeOfField,
          });
        }
      });
      if (clauseReqlist.length !== 0) {
        let logOp = null;
        if (index < allConditions.length - 1) {
          logOp = condition.operator;
        }
        conditionReqList.push({
          mandatory: condition.isMandatory,
          clauses: clauseReqlist,
          logicalOperation: logOp,
        });
      }
    });
    if (additionalParamMap) {
      const additionalClauses = [];
      const additionalParams = additionalParamMap;
      const additionalProps = Object.keys(additionalParams);
      if (additionalProps.length > 0) {
        const lastCondition = conditionReqList.slice(
          conditionReqList.length - 1
        )[0];
        lastCondition.logicalOperation = 'AND';
        additionalProps.forEach((property: string, index: number) => {
          additionalClauses.push({
            column: property,
            operation: '=',
            value: additionalParams[property],
            logicalOperation: index === 0 ? '' : 'AND',
          });
        });
        conditionReqList.push({
          mandatory: false,
          clauses: additionalClauses,
          logicalOperation: '',
        });
      }
    }
    return { conditions: conditionReqList };
  }

  /**
   * Transforms the search response to the client format
   *
   * @param response - Server response
   */
  public transformSearchResponse(response: any) {
    this.allSearchTableData = response;
    const pDef: PageDefinition = this.pagedData.pageDefinition;
    const pData: Array<TableRow> = this.pagedData.pageRows;
    pDef.pageNumber = +response['pageNo'];
    pDef.sortColumn = response['sortColumn'];
    pDef.sortOrder = +response['sortOrder'];
    pDef.recordCount = +response['totalRecords'];
    pDef.pageSize = +response['pageSize'];
    /* [LFWM-1956] - for Inventory Screen - fetching aggregateRecords from loadbyPage response to show aggregate of some columns
    for records which is present in server side [this aggregateRecords used only in Inventory Screen] */
    // pDef.aggregateRecords = response['aggregateRecords']; //

    for (let i = 0; i < response['content'].rows.length; i++) {
      // Adding code for DataTable View Structure Transformation
      const rowDefinition = response['pageData'].rows[i];
      const rowObject: any = new Object();
      rowObject.rIdx = rowDefinition.rIdx;
      // Flag to select/deselect the respective Checkbox (default : unchecked)
      rowObject.isSelected = false;
      // Iterating and inserting the server response cells to each Row Object in RowData Array
      rowObject.cells = {};
      rowDefinition.cells.forEach((cell: any) => {
        const cellInfo: any = new Object();
        cellInfo.cVal = cell.cVal;
        cellInfo.cValOrig = cell.cVal;
        cellInfo.cValPrev = cell.cVal;
        cellInfo.cName = cell.cName;
        rowObject.cells[cell.cName] = cellInfo;
      });
      if (pData.length < response['pageData'].rows.length) {
        pData.push(rowObject);
      }
    }
    this.gridOptions.api.setRowData(response.content);
    pData.push(response.content);
    this.populateLegData = [];
    this.populateLegData = pData;
  }

  /**
   * Method to perform search for a specific id (for eg, searching a document by its id)
   * @param idPropName - Id property
   * @param idPropVal - Value to be searched
   */
  public getItemDetailForId(
    idPropName: string,
    idPropVal: string,
    req?: any
  ): Observable<Response> {
    const storerKey = this.searchParams.storerKey;
    if (idPropName === 'sku' && storerKey) {
      // LFWM-2045 Creating search criteria for sku loadbypage by adding storerkey
      const searchCriteria = {
        conditions: [
          {
            mandatory: true,
            clauses: [
              {
                columnName: idPropName,
                operation: '=',
                value: idPropVal,
                logicalOperation: '',
              },
            ],
            logicalOperation: 'AND',
          },
          {
            mandatory: true,
            clauses: [
              {
                column: 'storerKey',
                operation: '=',
                value: storerKey,
                logicalOperation: '',
              },
            ],
            logicalOperation: 'AND',
          },
        ],
      };
      return this.getFirstItemForSearchCriteria(searchCriteria);
    } else {
      const searchCriteria = {
        conditions: [
          {
            mandatory: true,
            clauses: [
              {
                columnName: idPropName,
                operation: '=',
                value: idPropVal,
                logicalOperation: '',
                typeOfField: 'TEXTFIELD',
              },
            ],
            logicalOperation: 'AND',
          },
        ],
      };
      return this.getFirstItemForSearchCriteria(searchCriteria);
    }
  }

  public getDropDownValues(url): any {
    const hdr = new HttpHeaders({ api: 'notification' });
    const dataUrl$: any = this.http$.get(url, { headers: hdr });
    return dataUrl$.pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            console.debug('Error', error.error.statusMessage);
            return observableThrowError(error.error.statusMessage);
          }
        }
      })
    );
  }

  public getLookupDropDownValues(url, body): any {
    const hdr = new HttpHeaders({ api: 'notification' });
    const dataUrl$: any = this.http$.post(url, body, { headers: hdr });
    return dataUrl$.pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            console.debug('Error', error.error.statusMessage);
            return observableThrowError(error.error.statusMessage);
          }
        }
      })
    );
  }

  /*
   * This method returns the first item corresponding to the given search criteria.
   * The formatCriteria flag indicates if the criteria needs to be formatted specific to the service request.
   */
  public getFirstItemForSearchCriteria(criteria: any): Observable<Response> {
    const pageInfoReq = {
      // tableId: this.searchParams.tableId,
      pageNum: 1,
      sortColumn: this.pagedData.pageDefinition.sortColumn,
      sortOrder: this.pagedData.pageDefinition.sortOrder,
      pageSize: this.pagedData.pageDefinition.pageSize,
    };
    const payload = {
      pageInfo: pageInfoReq,
      searchCriteria: criteria,
      appId: 7,
      hiddenProperties: null,
      pinnedProperties: null,
      propertyIndexMap: null,
      columnOrder: null,
    };
    const dataUrl$ = this.http$
      .post(
        `api/data/` + this.searchParams.tableId + `/loadByPage`,
        JSON.stringify(payload)
      )
      .pipe(
        map((response: any) => {
          if (
            response['pageData'] === null ||
            response['pageData'].rows === null ||
            response['pageData'].rows.length <= 0
          ) {
            return [];
          }
          return response['pageData'].rows[0];
        }),
        catchError((error: any) => {
          return Observable.throw(
            'Failed to fetch  Item details :: ' + error.message
          );
        })
      );
    return dataUrl$;
  }

  /**
   * This method returns the next id for the given data table
   * @param moduleId - The module
   */
  public createUniqueId(moduleId: string) {
    if (moduleId === 'InvHold') {
      const dataUrl$ = this.http$.get('api/inbound/document/INVHOLDSTD/number');
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return Observable.throw(
              this.resourceService.get(error.error.statusMessage)
            );
          }
        }
      });
      return dataUrl$;
    } else {
      const dataUrl$ = this.http$.get(
        `api/inbound/document/` + moduleId + `/number`
      );
      catchError((error: any) => {
        return Observable.throw(
          'Failed to generate Unique ID for ' +
            moduleId +
            ' :: ' +
            error.message
        );
      });
      return dataUrl$;
    }
  }

  /**
   * Method which publishes all the search events
   * @param event - Search Event
   */
  public publishEvent($event: SearchEvent) {
    this.searchEventSubject.next($event);
  }

  public receiveEvent(): Observable<SearchEvent> {
    return this.searchEventSubject.asObservable();
  }

  public getDisplayMode() {
    return this.displayMode;
  }

  public setDisplayMode(displayMode: string) {
    this.displayMode = displayMode;
  }

  /**
   * Adds a new row locally (client side only) to the current data table. The new row will be put to the first position
   */
  public addNewRowToModel() {
    const rowData = this.createNewRow();
    const newRowEvent: SearchEvent = new SearchEvent(NEW_ROW_EVENT);
    newRowEvent.selectedRow = rowData;
    this.publishEvent(newRowEvent);
  }

  /**
   *  Insert a new row - seperating it from general method, as there could be further modifications
   * @param insertPosition : Index at which new row will be added to pagerows
   */
  public insertNewRow(insertPosition: number) {
    const rowData = this.createNewRow(insertPosition);
    const insertRowEvent: SearchEvent = new SearchEvent(INSERT_ROW_EVENT);
    insertRowEvent.selectedRow = rowData;
    // for INSERT_ROW_EVENT insertPosition will have the position at which new row should beinserted
    insertRowEvent.createdRowIndex = insertPosition;
    this.publishEvent(insertRowEvent);
  }

  /**
   *  Duplicate a row. duplicate acts as insert with difference only in event receiver actions
   * which might copy entire row or specific columns
   * @param insertPosition : Index at which duplicated row will be added to pagerows
   */
  public duplicateRow(insertPosition: number) {
    const rowData = this.createNewRow(insertPosition);
    const duplRowEvent: SearchEvent = new SearchEvent(DUPLICATE_ROW_EVENT);
    duplRowEvent.selectedRow = rowData;
    // for Duplicate_ROW_EVENT insertPosition will have the position at which new row should beinserted
    duplRowEvent.createdRowIndex = insertPosition;
    this.publishEvent(duplRowEvent);
  }

  /**
   * This method add a new row to the specified position. If the position is undefined,
   * the row is added to the zeroth position
   * @param insertPosition - The insertPosition
   * @returns new rows rowData
   */
  public createNewRow(insertPosition?: number): any {
    // if insertPostion is not given it will work as add new row , if insert postion is given new row will be inserted at that position
    if (!this.metadata || !this.metadata['columnList']) {
      return 'meta data not loaded';
    }
    const cellData = {};
    this.metadata['columnList'].map((column: any) => {
      const propName = column.propertyName;
      const propType = column.type;
      const dateDefaultValue = column.defaultVal;
      if (propType === 'TEXTFIELD') {
        let defaultValue: any;
        if (column.dataType === 'Integer') {
          if (column.defaultVal && column.defaultVal !== '') {
            defaultValue = column.defaultVal;
          } else {
            defaultValue = 0;
          }
        } else if (column.dataType === 'String') {
          if (column.defaultVal && column.defaultVal !== '') {
            defaultValue = column.defaultVal;
          } else {
            defaultValue = '';
          }
        } else if (column.dataType === 'Float') {
          if (column.defaultVal && column.defaultVal !== '') {
            defaultValue = column.defaultVal;
          } else {
            defaultValue = 0.0;
          }
        } else if (column.dataType === 'Currency') {
          if (column.defaultVal && column.defaultVal !== '') {
            defaultValue = column.defaultVal;
          } else {
            defaultValue = '0.00';
          }
        } else {
          defaultValue = null;
        }
        cellData[propName] = { cVal: defaultValue, cName: propName };
      } else if (propType === 'DATE' || propType === 'DATETIME') {
        // To set null value if no default value starts
        if (!cellData[dateDefaultValue]) {
          cellData[propName] = { cName: propName, cVal: null };
        } else {
          if (propType === 'DATE') {
            cellData[propName] = {
              cName: propName,
              cVal: this.dateConfigService.getFormattedDisplayDate(Date.now()),
            };
          } else if (propType === 'DATETIME') {
            cellData[propName] = {
              cName: propName,
              cVal: this.dateConfigService.getFormattedDisplayDateTime(
                Date.now()
              ),
            };
          }
        }
        // To set null value if no default value ends
      } else if (propType === 'DROPDOWN' || propType === 'EDITABLEDROPDOWN') {
        let defaultOption = '';
        if (column.values[0] && column.values[0].key) {
          defaultOption = column.defaultVal; // To set the default value if the user does not select any options
        }
        cellData[propName] = { cName: propName, cVal: defaultOption };
      } else if (propType === 'CHECKBOX' || propType === 'CHECKBOX_CHR') {
        // Adding CHECKBOX_CHR type denoting the saved value will be 'Y' or 'N'
        // CHECKBOX type denoting the saved value will be '1' or '0'
        cellData[propName] = { cVal: false };
      } else if (propType === 'DROPDOWN_MC') {
        let defaultValue: any;
        defaultValue = '';
        cellData[propName] = { cName: propName, cVal: defaultValue };
      } else if (propType === 'DROPDOWN_MS') {
        let defaultValue: any;
        if (column.defaultVal && column.defaultVal !== '') {
          defaultValue = column.defaultVal;
        } else {
          defaultValue = '';
        }
        cellData[propName] = { cName: propName, cVal: defaultValue };
      } else if (propType === 'TIME24') {
        let defaultValue: any;
        defaultValue = '00:00';
        cellData[propName] = { cName: propName, cVal: defaultValue };
      }
    });
    cellData['isNewItem'] = { cVal: true };
    const rowData = new TableRow();
    rowData.isSelected = false;
    rowData.isEdited = true;
    rowData.isNewRow = true;
    rowData.cells = cellData;
    this.pagedData.pageDefinition.recordCount++;
    rowData['rIdx'] = this.pagedData.pageRows.length + 1;
    if (!insertPosition) {
      // addmode
      // changing the logic as part of JIRA-649 to display the newly added row in the top above all rows in the grid
      if (!this.pagedData.pageRows) {
        this.pagedData.pageRows = [];
        this.pagedData.pageRows.push(rowData);
      } else {
        this.pagedData.pageRows.splice(0, 0, rowData);
      }
    } else {
      // mode === insert
      this.pagedData.pageRows.splice(insertPosition, 0, rowData);
    }
    // this.pagedData.pageRows.push(rowData);
    if (!this.searchStatus.hasResults) {
      this.searchStatus.hasResults = true;
    }
    return rowData;
  }

  /**
   * Deletes the selected row locally (client side only)
   *
   * @param idProperty - Id property
   * @param rowsToDelete - Value
   */
  public deleteRowsFromModel(
    idProperty: any,
    rowsToDelete: any,
    deleteByRowId?: boolean
  ) {
    if (this.metadata === null || this.metadata['columnList'] === null) {
      return 'meta data not loaded';
    }
    if (
      this.pagedData.pageRows === undefined ||
      this.pagedData.pageRows === null
    ) {
      return 'page data not loaded';
    }
    this.pagedData.pageRows = this.pagedData.pageRows.filter((row: any) => {
      if (
        row.cells === null ||
        !row.cells[idProperty] ||
        row.cells[idProperty] === null
      ) {
        return true;
      } else if (rowsToDelete.indexOf(row.cells[idProperty].cVal) !== -1) {
        return false;
      } else {
        return true;
      }
    });
    this.pagedData.pageDefinition.recordCount =
      this.pagedData.pageDefinition.recordCount - rowsToDelete.length;
    const evt = new SearchEvent(DELETE_ROW_EVENT);
    this.publishEvent(evt);
  }

  /*This function delete the newly added row, upon clicking on the 'x' appearing on the row */
  public deleteNewRowFromModel(rowIndex) {
    if (this.metadata === null || this.metadata['columnList'] === null) {
      return 'meta data not loaded';
    }
    if (
      this.pagedData.pageRows === undefined ||
      this.pagedData.pageRows === null
    ) {
      return 'page data not loaded';
    }
    const deletedRow = this.pagedData.pageRows[rowIndex];
    this.pagedData.pageRows.splice(rowIndex, 1);
    this.pagedData.pageDefinition.recordCount =
      this.pagedData.pageDefinition.recordCount - 1;
    const evt = new SearchEvent(DELETE_ROW_EVENT);
    evt.selectedRow = deletedRow;
    this.publishEvent(evt);
  }

  public saveSearchPreference(request: any) {
    const response$ = this.http$
      .post(`api/data/saveSearch`, request, { headers: this.hdr })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          if (error.error.errors.length !== 0) {
            if (error.error.errors[0].validationCode !== 200) {
              return Observable.throw(
                error.error.errors['0'].validationMessage
              );
            }
          }
        })
      );
    return response$;
  }

  public transformTablePreference(dataTableId, response) {
    const tablePreference = new TablePreference();
    if (response && response.length > 0) {
      response.forEach(preference => {
        const preferenceModel = new Preference();
        preferenceModel.preferenceId = preference['preferenceId'];
        preferenceModel.prefType = preference['prefType'];
        preferenceModel.dataTableId = dataTableId;
        preferenceModel.configuration = JSON.parse(preference['configuration']);
        if (preferenceModel.prefType === 'FAVOURITECONFIG') {
          tablePreference.favouriteConfig = preferenceModel;
        } else if (preferenceModel.prefType === 'COLUMNCONFIG') {
          tablePreference.columnConfig = preferenceModel;
        } else if (preferenceModel.prefType === 'COLUMNGROUPCONFIG') {
          tablePreference.columGroupConfig = preferenceModel;
        }
      });
    }

    if (!tablePreference.favouriteConfig) {
      const preferenceModelFav = new Preference();
      preferenceModelFav.preferenceId = null;
      preferenceModelFav.prefType = 'FAVOURITECONFIG';
      preferenceModelFav.dataTableId = dataTableId;
      preferenceModelFav.configuration = { favourite: false };
      tablePreference.favouriteConfig = preferenceModelFav;
    }

    if (!tablePreference.columnConfig) {
      const preferenceModelColumnConfig = new Preference();
      preferenceModelColumnConfig.preferenceId = null;
      preferenceModelColumnConfig.prefType = 'COLUMNCONFIG';
      preferenceModelColumnConfig.dataTableId = dataTableId;
      preferenceModelColumnConfig.configuration = {
        hiddenPropertiesList: [],
        pinnedPropertiesList: [],
        columnWidthsObject: {},
        propertyIndexMap: {},
        pageDefinitionObject: {
          sortColumn: null,
          sortOrder: 0,
          pageSize: 10,
        },
      };
      tablePreference.columnConfig = preferenceModelColumnConfig;
    }

    if (!tablePreference.columGroupConfig) {
      const preferenceModelColGrp = new Preference();
      preferenceModelColGrp.preferenceId = null;
      preferenceModelColGrp.prefType = 'COLUMNGROUPCONFIG';
      preferenceModelColGrp.dataTableId = dataTableId;
      preferenceModelColGrp.configuration = {};
      tablePreference.columGroupConfig = preferenceModelColGrp;
    }
    return tablePreference;
  }

  /**
   * Method to get the maximum value for a column in the table
   */
  public getMaxValueForIdProperty() {
    const response$ = this.http$.post(
      `api/data/` + this.searchParams.tableId + `/function`,
      {
        functionType: 'MAX',
        propertyNames: [this.searchParams.idProperty],
        filterCriteria: this.searchCriteria,
      }
    );
    catchError((error: any) => {
      return Observable.throw('Could not fetch the max value');
    });
    return response$;
  }

  public setNewPageEventInProgress(newPageEventInProgress: any) {
    this.newPageEventInProgress = newPageEventInProgress;
  }

  public getNewPageEventInProgress(): boolean {
    return this.newPageEventInProgress;
  }

  public clone() {
    const targetService: SearchService = new SearchService(
      this.http$,
      this.searchParams,
      this.searchStatus,
      this.resourceService,
      null,
      this.alertService,
      this.dateConfigService,
      this.userService
    );
    targetService.metadata = this.metadata;
    targetService.pagedData = this.pagedData;
    targetService.searchCriteria = this.searchCriteria;
    targetService.newPageEventInProgress = this.newPageEventInProgress;
    targetService.displayMode = this.displayMode;
    return targetService;
  }

  public fetchAutoSuggestionResults(isNextPage: boolean): Observable<any> {
    console.debug('isNextPage', isNextPage);

    this.pagedData.pageRows = [];
    const tableId = this.searchParams.tableId;
    if (isNextPage) {
      this.pagedData.pageDefinition.pageNumber++;
    } else {
      this.pagedData.pageDefinition.pageNumber = 1;
    }
    const pageInfoReq = {
      tableId: tableId,
      pageNo: this.pagedData.pageDefinition.pageNumber,
      pageSize: this.pagedData.pageDefinition.pageSize,
      fullFetch: this.searchParams.isFullFetch,
    };
    const payload = {
      pageInfo: pageInfoReq,
      searchCriteria: this.formatSearchCriteria(
        this.searchCriteria,
        this.searchParams.additionalParamMap
      ),
    };
    const dataUrl$ = this.http$
      .post(
        `api/data/` + this.searchParams.tableId + `/autosuggest`,
        JSON.stringify(payload)
      )
      .pipe(
        map((response: any) => {
          // these code is added to avoid duplicate entries when consecutives keys are pressed fastly
          if (!isNextPage) {
            this.pagedData.pageRows = [];
          }
          return this.transformSearchResponse(response);
        }),
        catchError((error: any) => {
          return Observable.throw(
            'Failed to fetch  Search Results :: ' + error.message
          );
        })
      );
    return dataUrl$;
  }

  /*  public fetchAutoSuggestionResultsForFilter(): Observable<Response> {
      this.pagedData.pageRows = [];
      const tableId = this.searchParams.tableId;
      this.pagedData.pageDefinition.pageNumber = 1;
      const pageInfoReq = {
        tableId: tableId,
        pageNo: this.pagedData.pageDefinition.pageNumber,
        pageSize: this.pagedData.pageDefinition.pageSize,
        fullFetch: this.searchParams.isFullFetch
      };
      const payload = {
        pageInfo: pageInfoReq,
        searchCriteria: this.formatSearchCriteria(this.searchCriteria, this.searchParams.additionalParamMap, this.filterCondition)
      };
      const dataUrl$ = this.http$.post(`api/data/` + this.searchParams.tableId + `/autosuggest`, JSON.stringify(payload))
        .map((response: any) => {
          return this.transformSearchResponse(response);
        })
        .catch((error: any) => {
          return Observable.throw('Failed to fetch  Search Results :: ' + error.message);
        });
      return dataUrl$;
    } */

  /*  public performFilter() {
      this.fetchAutoSuggestionResultsForFilter()
        .subscribe((data: any) => {
          this.searchStatus.hasResults = true;
          this.searchStatus.recordCount = this.pagedData.pageDefinition.recordCount;
          const event = new SearchEvent(NEW_SEARCH_EVENT);
          event.isSearchSuccess = true;
          this.publishEvent(event);
        });
    } */

  public exportData(format: string, tableId?: string) {
    const tableIdFromSearchParams = this.searchParams.tableId;
    if (tableId == null) {
      tableId = tableIdFromSearchParams;
    }
    const pageInfoReq = {
      tableId: tableId,
      sortColumn: this.pagedData.pageDefinition.sortColumn,
      sortOrder: this.pagedData.pageDefinition.sortOrder,
      fullFetch: this.searchParams.isFullFetch,
    };
    const payload = {
      pageInfo: pageInfoReq,
      searchCriteria: this.formatSearchCriteria(
        this.searchCriteria,
        this.searchParams.additionalParamMap,
        this.filterCondition
      ),
    };

    const response$ = this.http$
      .post('api/data/' + tableId + '/export/' + format, payload)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return Observable.throw(error);
        })
      );
    return response$;
  }

  // method returns the metadata for the given property
  public getMetadataForProperty(property) {
    let columnMetaData: any = null;
    if (this.metadata && property) {
      this.metadata.columnList.forEach(column => {
        if (column.propertyName === property) {
          columnMetaData = column;
          return;
        }
      });
    }
    return columnMetaData;
  }
  public formatSaveSearchCriteria(
    searchCriteria,
    additionalParamMap,
    filterCondition?: any
  ) {}
  public performSearchForPopulate(): any {}
  public downloadExcelTemplateForUpload(module) {}

  public loadBulkUpdateButtons(): any {
    const selectedRows = this.getSelectedRows();
    if (selectedRows.length <= 0) {
      if (this.entityConfigMetaData.moduleId === 'ordersearchtd') {
        this.alertService
          .clearAll()
          .warn(this.resourceService.get('msg_select_order_rows'));
      } else {
        this.alertService
          .clearAll()
          .warn(this.resourceService.get('msg_select_rows'));
      }
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
    const request = [];
    let buttonsArray = [];
    const privilegedButtonArray = [];
    let fieldName = '';
    let url = '';
    if (this.entityConfigMetaData.moduleId === 'ordersearchtd') {
      fieldName = 'GV_ORDER_ID';
      url = 'api/poms/order/bulk/update/statuslist';
    } else if (this.entityConfigMetaData.moduleId === 'shipmentbookingtd') {
      fieldName = 'GC_BOOKING_ID';
      url = 'api/poms/shipment/bulk/update/statuslist';
    }
    selectedRows.forEach(row => {
      request.push(row.cells[fieldName].cVal);
    });
    return new Promise((resolve, reject) => {
      this.getBulkUpdateButtons(request, url).subscribe(
        (response: any) => {
          buttonsArray = response;
          buttonsArray.forEach(element => {
            if (
              this.userService.pomsUserInfo.userPermissionsList.indexOf(
                element.statusBtnPrivilege
              ) !== -1
            ) {
              privilegedButtonArray.push(element);
            }
          });
          if (privilegedButtonArray.length <= 0) {
            if (this.entityConfigMetaData.moduleId === 'ordersearchtd') {
              this.alertService
                .clearAll()
                .warn(this.resourceService.get('error_msg_no_order_action'));
            } else {
              this.alertService
                .clearAll()
                .warn(this.resourceService.get('msg_no_action'));
            }
          }
          resolve(privilegedButtonArray);
        },
        error => {
          this.alertService
            .clearAll()
            .error(
              this.resourceService.get(error.error.errors[0].validationMessage)
            );
        }
      );
    });
  }

  public setStatusForBookings(status): any {
    const selectedRows = this.getSelectedRows();
    if (selectedRows.length <= 0) {
      if (this.entityConfigMetaData.moduleId === 'ordersearchtd') {
        this.alertService
          .clearAll()
          .warn(this.resourceService.get('msg_select_order_rows'));
      } else {
        this.alertService
          .clearAll()
          .warn(this.resourceService.get('msg_select_rows'));
      }
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
    const request = [];
    selectedRows.forEach(row => {
      request.push(row.cells['GC_BOOKING_ID'].cVal);
    });
    const url = 'api/poms/shipment/bulk/update/' + status + '/status';
    return new Promise((resolve, reject) => {
      this.setStatusUpdate(url, request).subscribe(
        (response: any) => {
          resolve(response);
        },
        error => {
          this.alertService
            .clearAll()
            .error(
              this.resourceService.get(error.error.errors[0].validationMessage)
            );
        }
      );
    });
  }

  @LoaderEnabled()
  public getBulkUpdateButtons(request, url): Observable<any> {
    return this.http$.post(url, request).pipe(
      map(
        (response: any) => {
          return response;
        },
        error => {
          return error;
        }
      )
    );
  }

  @LoaderEnabled()
  public setStatusUpdate(url, request): Observable<any> {
    return this.http$.put(url, request).pipe(
      map(
        (response: any) => {
          return response;
        },
        error => {
          return error;
        }
      )
    );
  }

  public setStatusForOrder(status, date): any {
    const selectedRows = this.getSelectedRows();
    if (selectedRows.length <= 0) {
      if (this.entityConfigMetaData.moduleId === 'ordersearchtd') {
        this.alertService
          .clearAll()
          .warn(this.resourceService.get('msg_select_order_rows'));
      } else {
        this.alertService
          .clearAll()
          .warn(this.resourceService.get('msg_select_rows'));
      }
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
    const orderIds = [];
    selectedRows.forEach(row => {
      orderIds.push(row.cells['GV_ORDER_ID'].cVal);
    });
    const request = {
      orderIds: orderIds,
      date: date,
    };
    const url = 'api/poms/order/bulk/update/' + status + '/status';
    return new Promise((resolve, reject) => {
      this.setStatusUpdate(url, request).subscribe(
        (response: any) => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
